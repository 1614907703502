import React, {  useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";

function Hero() {
  const sliderRef = useRef();

  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    loop: true,
    arrows: true,
    infinite: true,
    autoplay: true,
  };

  function CustomNextArrow() {
    const handleClick = () => {
      sliderRef.current.slickNext();
    };

    return (
      <button className="slider-btn slider-next" onClick={handleClick}>
        <i className="fi-rs-angle-right"></i>
      </button>
    );
  }

  function CustomPrevArrow() {
    const handleClick = () => {
      sliderRef.current.slickPrev();
    };

    return (
      <button className="slider-btn slider-prev" onClick={handleClick}>
        <i className="fi-rs-angle-left"></i>
      </button>
    );
  }

  return (
    <div >
      <section className="home-slider position-relative pt-50">
      <div className="hero-slider-1 dot-style-1 dot-style-1-position-1 container">
        <Slider ref={sliderRef} {...settings}>
          <div className="single-hero-slider single-animation-wrap">
            <div className="container">
              <div className="row align-items-center slider-animated-1">
                <div className="col-lg-5 col-md-6">
                  <div className="hero-slider-content-2">
                    <h4 className="animated">ENJOY THE Winter VIBES</h4>
                    <h2 className="animated fw-900">Winter Shawl </h2>
                    <h1 className="animated fw-900 text-brand">
                    Collection
                    </h1>
                    <p className="animated">
                      Save more then 30% off
                    </p>
                    <Link
                      className="animated btn btn-brush btn-brush-3"
                      to={"category/Wool Shawl"}
                    >
                      Discover Now
                    </Link>
                  </div>
                </div>
                <div className="col-lg-7 col-md-6 d-none d-md-block">
                  <div className="single-slider-img single-slider-img-1">
                    <img
                      className="animated slider-1-1"
                      src="https://res.cloudinary.com/dcqxzru7f/image/upload/v1727787355/ogdwvemmpmc6qbgqbrjc.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="single-hero-slider single-animation-wrap">
            <div className="container">
              <div className="row align-items-center slider-animated-1">
                <div className="col-lg-5 col-md-6">
                  <div className="hero-slider-content-2">
                    <h4 className="animated">WOMEN SHAWLS</h4>
                    <h2 className="animated fw-900">Fashion Trending</h2>
                    <h1 className="animated fw-900 text-7">Winter Collection</h1>
                    <p className="animated">
                      Save more then 30% off
                    </p>
                    <Link
                      className="animated btn btn-brush btn-brush-2"
                      to={"category/Pashmina Shawl"}
                    >
                      Discover Now
                    </Link>
                  </div>
                </div>
                <div className="col-lg-7 col-md-6 d-none d-md-block">
                  <div className="single-slider-img single-slider-img-1">
                    <img
                      className="animated slider-1-2"
                      src="https://res.cloudinary.com/dcqxzru7f/image/upload/v1727787360/vwmgpv59qkdcuffkqote.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="single-hero-slider single-animation-wrap">
            <div className="container">
              <div className="row align-items-center slider-animated-1">
                <div className="col-lg-5 col-md-6">
                  <div className="hero-slider-content-2">
                    <h4 className="animated">Winter JUST GOT HOTTER</h4>
                    <h2 className="animated fw-900">With Our Winter Season</h2>
                    <h1 className="animated fw-900 text-8">Men Shawls</h1>
                    <p className="animated">
                      Clothing, Men & Women Shawls...
                    </p>
                    <Link
                      className="animated btn btn-brush btn-brush-1"
                      to={"category/Men Shawl"}
                    >
                      Discover Now
                    </Link>
                  </div>
                </div>
                <div className="col-lg-7 col-md-6 d-none d-md-block">
                  <div className="single-slider-img single-slider-img-1">
                    <img
                      className="animated slider-1-3"
                      src="https://res.cloudinary.com/dcqxzru7f/image/upload/v1727787353/jyov52xgnmekbscafufn.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Slider>
      </div>
      <div className="slider-arrow">
        <CustomPrevArrow />
        <CustomNextArrow />
      </div>
    </section>
    </div>
  );
}

export default Hero;
